import React, {
  Dispatch, SetStateAction,
  useCallback, useState,
} from 'react';
import {
  Box, DialogTitle, Divider, IconButton, Modal,
} from '@mui/material';
import { Close, Settings } from '@mui/icons-material';
import { SentenceTranslateType } from '../Type';
import { styleTopicModal } from '../style/styled';
import { Rules } from './Rules';

type ButtonEditRulesSentenceProps = {
  sentence: SentenceTranslateType,
  setSentence: Dispatch<SetStateAction<SentenceTranslateType>>,
};

export const ButtonEditRulesSentenсe: React.FC<ButtonEditRulesSentenceProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const clickShow = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  // console.log(document.documentElement.clientHeight);

  const handleCancel = async () => {
    setIsModalVisible(false);
  };
  return (
    <>
        <IconButton disabled={!!props.sentence.isEditRules} color="success" aria-label="edit rules" onClick={clickShow}>
            <Settings/>
        </IconButton>
      <Modal open={isModalVisible}
             onClose={handleCancel} >
        <Box sx={styleTopicModal} >
            <DialogTitle>
                <Box display="flex" alignItems="center" fontSize={16}>
                    <Box flexGrow={1}>Edit Rules</Box>
                    <Box>
                        <IconButton onClick={handleCancel}><Close /></IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <Divider />
            <Box marginTop={'20px'}>
                {/* <div style={{ minHeight: 500, height: document.documentElement.clientHeight - 100 - 50 }} > */}
                <div>
                  {
                    <Rules sentence={props.sentence} setSentence={props.setSentence}/>
                  }
                </div>
            </Box>
        </Box>
      </Modal>
    </>
  );
};
