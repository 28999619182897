import React, {
  ChangeEvent, Dispatch, SetStateAction,
  useCallback,
  useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Alert, AlertTitle,
  Box, Button, Chip, Divider, Grid, Snackbar, TextField,
} from '@mui/material';
import { Save } from '@mui/icons-material';
import {
  Badge,
  Card,
  Col, Empty, Row, Tabs,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import { selectSentences, selectSentenceTranslate } from '../app/store';
import {
  ConstRules,
  RuleType, SentenceTranslateType, TopicType, TranslateType, WordType,
} from '../Type';
import { changeWords, loadSentence, pushRules } from '../features/sentence/sentenceTranslateSlice';
import { updateTopic } from '../features/topic/topicSlice';
import { ButtonDeleteRules } from './ButtonDeleteRules';
import { HelperData } from './helperData';

type RulesProps = {
  sentence: SentenceTranslateType,
  setSentence: Dispatch<SetStateAction<SentenceTranslateType>>,
};

export const Rules: React.FC<RulesProps> = (props) => {
  const dispatch = useDispatch();
  const topic = useSelector(selectSentences) as TopicType;
  const sentenceTranslate = useSelector(selectSentenceTranslate);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [checkedStartWords, setCheckedStartWords] = useState<WordType[]>([]);
  const [checkedWords, setCheckedWords] = useState<WordType[]>([]);
  const [checkedRules, setCheckedRules] = useState<RuleType[]>([]);
  const [description, setDescription] = useState<string>('');
  const [errorTranslate, setErrorTranslate] = useState<Boolean>(false);
  const [flagErrorTranslate, setFlagErrorTranslate] = useState(false);
  const [currentTab, setCurrentTab] = useState<ConstRules>(HelperData.rulesNew[0]);
  const [addTabAsChip, setAddTabAsChip] = useState<string[]>([]);

  const { TabPane } = Tabs;

  const onChange = (key: string) => {
    const tab = HelperData.rulesNew.filter((rule) => rule.key === key)[0];
    setCurrentTab(tab);
  };

  useEffect(() => {
    dispatch(loadSentence(props.sentence));
  }, []);

  useEffect(() => {
    if (flagErrorTranslate) {
      setTimeout(() => {
        setFlagErrorTranslate(false);
      }, 1000);
    }
  }, [flagErrorTranslate]);

  useEffect(() => {
    if (sentenceTranslate.text && !sentenceTranslate.words.length) {
      const sentence = topic.topic_data
        .filter((subSentence) => subSentence.id === sentenceTranslate.parentId)[0];

      let beforeSentence: SentenceTranslateType | undefined;
      let i = 0;
      for (i = 0; i < sentence.sentencesTranslate.length; i++) {
        if (sentence.sentencesTranslate[i].id === sentenceTranslate.id) {
          if (i > 0) {
            beforeSentence = sentence.sentencesTranslate[i - 1];
            break;
          }

          beforeSentence = undefined;
          break;
        }
      }
      dispatch(changeWords({ text: sentenceTranslate.text, beforeSentence }));

      if (i === 1 && beforeSentence && !beforeSentence.words.length) {
        const allWords = beforeSentence.text.split(' ')
          .filter((word) => word)
          .map((subWord) => ({
            id: uuidv4(),
            text: subWord,
          } as WordType));
        console.log('updateTopic!!!!!!!!!!!!!!!!!!!!!');
        dispatch(updateTopic({ ...beforeSentence, words: allWords }));
      }

      setIsUpdate(true);
    }

    props.setSentence(sentenceTranslate);
  }, [sentenceTranslate]);

  useEffect(() => {
    if (isUpdate) {
      console.log('updateTopic', sentenceTranslate);
      dispatch(updateTopic(sentenceTranslate));
      setIsUpdate(false);
    }
  }, [isUpdate]);

  const getTranslateWord = async (word:string) => {
    const url = `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`;
    try {
      const response = (await axios.get<TranslateType[]>(url));
      return response.data;
    } catch (e) {
      setErrorTranslate(true);
      setFlagErrorTranslate(true);
    }
    return [];
  };

  const saveRules = useCallback(async () => {
    if (!checkedWords.length || !checkedRules.length || !checkedStartWords.length) {
      return;
    }

    const newCheckedWords = checkedWords.map((elem) => ({ ...elem }));
    for (const word of newCheckedWords) {
      const correctWord = word.text.replace(/[^a-z\s]/gi, '');
      // eslint-disable-next-line no-await-in-loop
      word.translate = await getTranslateWord(correctWord);
    }

    dispatch(pushRules({
      startWords: checkedStartWords,
      words: newCheckedWords,
      rules: checkedRules,
      description,
      id: uuidv4(),
    }));

    setIsUpdate(true);
    setCheckedRules([]);
    setAddTabAsChip([]);
    setCheckedWords([]);
    setCheckedStartWords([]);
  }, [checkedWords, checkedRules, checkedStartWords, description]);

  if (!sentenceTranslate) {
    return null;
  }
  const sentenceStart = sentenceTranslate.parentId ? topic.topic_data.filter((sentence) => sentence.id === sentenceTranslate.parentId)[0].sentencesTranslate[0] : undefined;
  // console.log(sentenceTranslate.linkRules, currentTab);
  return (
    <>
      <Row gutter={10}>
        <Col span={18}>
          <Row gutter={5}>
            <Col className="gutter-row" span={24}>
              <Divider orientation="horizontal" flexItem style={{ margin: '10px 0' }}> SENTENCE </Divider>
              <Box style={{ maxHeight: '500px' }}>
                <Row gutter={5}>
                  <Col className="gutter-row" span={24}>
                    <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
                      {
                        errorTranslate
                          ? <Snackbar open={flagErrorTranslate} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                              <Alert severity="error">
                                <AlertTitle>Error!</AlertTitle>
                                Transtate didn`t found!
                              </Alert>
                            </Snackbar>
                          : null
                      }
                      {
                          !!sentenceTranslate.parentId
                          && !!sentenceStart
                          && sentenceStart
                            .words.map((word, index) => (
                                  <Box key={index}>
                                    <Chip id={word.id}
                                          label={word.text}
                                          variant={checkedStartWords.filter((oneWord) => oneWord.id === word.id).length ? 'filled' : 'outlined' }
                                          color="warning"
                                          onClick={(e: any) => {
                                            const id = e.target.offsetParent.id || e.target.id;
                                            // console.log(e.target);
                                            if (!checkedStartWords.filter((oneWord) => oneWord.id === id).length) {
                                              const newCheckedStartWords: WordType[] = [
                                                ...checkedStartWords,
                                                {
                                                  id,
                                                  text: sentenceStart.words.filter((oneWord) => oneWord.id === id)[0].text,
                                                }];

                                              setCheckedStartWords(sentenceStart.words
                                                .filter((stWord) => newCheckedStartWords.filter((newCheckedWord) => newCheckedWord.id === stWord.id).length));
                                            } else {
                                              setCheckedStartWords(checkedStartWords.filter((oneWord) => oneWord.id !== id));
                                            }
                                          }}
                                    />
                                  </Box>
                            ))
                      }
                    </Box>
                  </Col>
                </Row>
                <Divider orientation="horizontal" flexItem style={{ margin: '10px 0' }}> TRANSLATE </Divider>
                <Row gutter={5}>
                  <Col className="gutter-row" span={24}>
                    <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
                      {
                        sentenceTranslate.words.map((word, index) => (
                            <Box key={index}>
                              <Chip id={word.id}
                                    label={word.text}
                                    variant={checkedWords.filter((oneWord) => oneWord.id === word.id).length ? 'filled' : 'outlined' }
                                    color="primary"
                                    onClick={(e: any) => {
                                      const id = e.target.offsetParent.id || e.target.id;
                                      // console.log(e.target);
                                      if (!checkedWords.filter((oneWord) => oneWord.id === id).length) {
                                        const newCheckedWords: WordType[] = [
                                          ...checkedWords,
                                          {
                                            id,
                                            text: sentenceTranslate.words.filter((oneWord) => oneWord.id === id)[0].text,
                                          }];

                                        setCheckedWords(sentenceTranslate.words
                                          .filter((stWord) => newCheckedWords.filter((newCheckedWord) => newCheckedWord.id === stWord.id).length));
                                      } else {
                                        setCheckedWords(checkedWords.filter((oneWord) => oneWord.id !== id));
                                      }
                                    }}
                              />
                            </Box>
                        ))
                      }
                    </Box>
                  </Col>
                </Row>
                <Divider orientation="horizontal" flexItem style={{ margin: '10px 0' }}> RULES </Divider>
                <Row gutter={5}>
                  <Col className="gutter-row" span={24}>
                    <Box display={'flex'} gap={'5px'} flexWrap={'wrap'}>
                      <Tabs defaultActiveKey="1" onChange={onChange}>
                        { HelperData.rulesNew.map((tab, index) => <TabPane
                              tab={<>
                                <Badge dot={addTabAsChip.includes(tab.key)}
                                       status="success"
                                       size={'small'}
                                       offset={[5, 2]}>{tab.tab}
                                </Badge>
                              </>} key={tab.key} id={`i_${index}`}>
                            <Box display={'flex'} gap={'5px'} flexWrap={'wrap'} key={index}>
                              {tab.children.map((ch, sindex) => <Box key={sindex}>
                                <Chip id={`${ch.id}`}
                                      label={ch.text}
                                      variant={checkedRules.filter((oneRule) => +oneRule.ruleId === +ch.id).length ? 'filled' : 'outlined' }
                                      color="success"
                                      onClick={(e: any) => {
                                        const id = e.target.offsetParent.id || e.target.id;
                                        // console.log(e.target, checkedRules.filter((oneRule) => +oneRule.ruleId === +id), id);
                                        if (!checkedRules.filter((oneRule) => +oneRule.ruleId === +id).length) {
                                          if (addTabAsChip.length === 0 || !addTabAsChip.includes(currentTab.key)) {
                                            setCheckedRules((oldValue) => [
                                              ...oldValue,
                                              {
                                                ruleId: +currentTab.key,
                                                rule: currentTab.tab,
                                              }]);
                                            setAddTabAsChip([...addTabAsChip, currentTab.key]);
                                            console.log(addTabAsChip);
                                          }
                                          setCheckedRules((oldValue) => [
                                            ...oldValue,
                                            {
                                              ruleId: id,
                                              rule: ch.text,
                                            }]);
                                        } else {
                                          setCheckedRules(checkedRules.filter((oneRule) => +oneRule.ruleId !== +id));
                                          setAddTabAsChip(addTabAsChip.filter((chip) => chip !== currentTab.key));
                                        }
                                      }}
                                />
                              </Box>)}
                            </Box>
                          </TabPane>)}
                      </Tabs>
                    </Box>
                  </Col>
                </Row>
              </Box>
            </Col>
          </Row>
          <Row gutter={5}>
            <Col className="gutter-row" span={24}>
              <Box>
                <TextField label={'Description'} style={{ width: '100%', margin: '20px 0' }}
                           multiline
                           rows={3}
                           maxRows={6} onChange={(event:ChangeEvent<HTMLInputElement>) => {
                             setDescription(event.target.value);
                           }}/>
              </Box>
            </Col>
          </Row>
          <Row gutter={5}>
            <Col className="gutter-row" span={24}>
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
              }}>
                <Button variant="contained" startIcon={<Save />} onClick={saveRules}>
                  Save Rules
                </Button>
              </Box>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Grid >
            <Box sx={{
              margin: '0px',
              width: '100%',
              height: 500,
              overflowY: 'scroll',
              backgroundColor: '#FDFDFD',
              border: '1px solid #E0E0E0',
            }}>
              {
                !sentenceTranslate.linkRules.length
                  ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No rules'} />
                  : sentenceTranslate.linkRules.map((linkRule, index) => (
                    <Row key={index} style={{ padding: '10px 0', alignItems: 'center' }}>
                      <Col span={24}>
                        <Card title={`Rule ${index + 1}`} extra={ <ButtonDeleteRules linkRuleId={linkRule.id} />} key={index} hoverable
                              style={{ width: '100%' }}
                              bodyStyle={{ padding: '10px' }}
                              headStyle={{ fontSize: '14px' }}
                        >
                            <Row>
                              <Col span={24}>
                                <Box>
                                  <div style={{ display: 'flex', gap: 4 }}>
                                    {
                                      linkRule.words.map((word) => <p id={word.id} key={word.id} style={{ color: '#1976d2' }}>{word.text}</p>)
                                    }
                                  </div>
                                  <div style={{ display: 'flex', gap: 4 }}>
                                    {
                                      linkRule.startWords.map((startWord) => <p id={startWord.id} key={startWord.id} style={{ color: '#ed6c02' }}>{startWord.text}</p>)
                                    }
                                  </div>
                                  <div style={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
                                    {
                                      linkRule.rules.map((rule) => <p id={`${rule.ruleId}`} key={rule.ruleId} style={{ color: '#2e7d32' }}>{rule.rule}</p>)
                                    }
                                  </div>
                                  <div style={{ display: 'flex', gap: 4 }}>
                                    {
                                      linkRule.description
                                    }
                                  </div>
                                  {/* <div style={{ display: 'flex', justifyContent: 'right' }}> */}
                                  {/*  <ButtonDeleteRules linkRuleId={linkRule.id} /> */}
                                  {/* </div> */}
                                </Box>
                              </Col>
                            </Row>

                        </Card>
                      </Col>

                    </Row>
                  ))
              }
            </Box>
          </Grid>
        </Col>
      </Row>
    </>
  );
};
