import React, {
  useEffect,
} from 'react';
import {
  Badge,
  Box, IconButton, Stack,
} from '@mui/material';
import { NotificationsRounded, Person2Rounded } from '@mui/icons-material';

type HeaderContentProps = {

};

export const HeaderContent: React.FC<HeaderContentProps> = () => {
  useEffect(() => {
    console.log('HeaderContent');
  }, []);

  return (
      <Box sx={{
        display: 'flex',
        justifyContent: 'right',
        padding: '24px',
      }}>
          <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                <IconButton >
                    <Badge badgeContent={4} color="error">
                    <NotificationsRounded width={20} height={20}/>
                    </Badge>
                </IconButton>
              <IconButton >
                <Person2Rounded/>
              </IconButton>
          </Stack>
      </Box>
  );
};
