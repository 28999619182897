import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import {
  LinkRuleType,
  SentenceTranslateType, WordType,
} from '../../Type';

const initialState: SentenceTranslateType = {
  id: uuidv4(),
  parentId: '',
  text: '',
  words: [],
  linkRules: [],
  isEditRules: true,
};

export const sentenceTranslateSlice = createSlice({
  name: 'sentenceTranslate',
  initialState,
  reducers: {
    reset: () => ({ ...initialState, id: uuidv4() }),
    changeWords: (state: SentenceTranslateType, action:PayloadAction<{ text: string, beforeSentence?: SentenceTranslateType }>) => {
      // const allWords = action.payload.text.replace(/[^aA-zZ]/gm, ' ').split(' ')
      const allWords = action.payload.text.split(' ')
        .filter((word) => word)
        .map((subWord) => ({
          id: uuidv4(),
          text: subWord,
        } as WordType));

      const linkRules: LinkRuleType[] = [];

      if (action.payload.beforeSentence) {
        action.payload.beforeSentence.linkRules.forEach((rule) => {
          let arrayWordsForRule: WordType[] = [];

          if (linkRules.filter((linkRule) => linkRule.words.map((w) => w.text).join(',') === rule.words.map((w) => w.text).join(',')).length) {
            return;
          }

          for (let i = 0; i < allWords.length; i++) {
            for (let j = 0; j < rule.words.length; j++) {
              if (j === arrayWordsForRule.length && allWords[i].text === rule.words[j].text) {
                arrayWordsForRule.push({
                  ...allWords[i],
                  translate: rule.words[j].translate,
                });
              }
            }
            if (arrayWordsForRule.length === rule.words.length) {
              linkRules.push({
                ...rule,
                id: uuidv4(),
                words: [...arrayWordsForRule],
              });
              console.log(arrayWordsForRule);
              arrayWordsForRule = [];
            }
          }
        });
      }

      return {
        ...state,
        words: allWords,
        linkRules,
      } as SentenceTranslateType;
    },
    loadSentence: (state: SentenceTranslateType, action:PayloadAction<SentenceTranslateType>) => action.payload,
    pushRules: (state: SentenceTranslateType, action:PayloadAction<LinkRuleType>) => ({
      ...state,
      linkRules: [...state.linkRules, action.payload],
    }),
    deleteRules: (state: SentenceTranslateType, action:PayloadAction<string>) => {
      const newRules = state.linkRules.filter((link) => link.id !== action.payload);

      return {
        ...state,
        linkRules: newRules,
      };
    },
  },
});

export const {
  reset, changeWords, loadSentence, pushRules, deleteRules,
} = sentenceTranslateSlice.actions;

export default sentenceTranslateSlice.reducer;
