import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import {
  SentenceTranslateType, SentenceType, TopicType,
} from '../../Type';

const initialState: TopicType = {
  topic_id: uuidv4(),
  author_id: '',
  name: '',
  topic_group: '',
  tags: '',
  theme: '',
  topic_data: [],
};

export const topicSlice = createSlice({
  name: 'topic',
  initialState,
  reducers: {
    reset: () => ({ ...initialState, topic_id: uuidv4() }),
    addSentenсe: (state: TopicType, action:PayloadAction<SentenceTranslateType>) => {
      const sentencesTranslate: SentenceTranslateType[] = [];

      const parentId = uuidv4();

      for (let i = 0; i <= 5; i++) {
        sentencesTranslate.push({
          ...action.payload, id: uuidv4(), parentId, isEditRules: true,
        });
      }

      const stateSentence: SentenceType = {
        id: parentId,
        sentencesTranslate,
      };
      state.topic_data = [
        ...state.topic_data,
        stateSentence,
      ];
    },
    delSentenсe: (state: TopicType, action:PayloadAction<string>) => {
      state.topic_data = state.topic_data.filter((sentence) => sentence.id !== action.payload);
    },
    changeSentenсe: (state: TopicType, action:PayloadAction<SentenceTranslateType>) => {
      console.log('changeSentenсe');
      let flagChange = false;

      state.topic_data = state.topic_data.map((sentence) => {
        if (sentence.id === action.payload.parentId) {
          const newSentecesTranslate = sentence.sentencesTranslate.map((sentenceTranslate) => {
            if (!sentenceTranslate.isEditRules) {
              return sentenceTranslate;
            }

            if (sentenceTranslate.id === action.payload.id) {
              flagChange = true;
            }

            if (flagChange) {
              return { ...action.payload, id: sentenceTranslate.id, isEditRules: sentenceTranslate.isEditRules };
            }
            return sentenceTranslate;
          });

          return {
            ...sentence,
            sentencesTranslate: newSentecesTranslate,
          };
        }
        return sentence;
      });
    },
    loadTopic: (state: TopicType, action:PayloadAction<TopicType>) => action.payload,
    updateTopic: (state: TopicType, action:PayloadAction<SentenceTranslateType>) => ({
      ...state,
      topic_data: state.topic_data.map((sentence) => {
        if (sentence.id === action.payload.parentId) {
          const newSentecesTranslate = sentence.sentencesTranslate.map((sentenceTranslate) => {
            if (sentenceTranslate.id === action.payload.id) {
              return { ...action.payload };
            }
            return sentenceTranslate;
          });

          return {
            ...sentence,
            sentencesTranslate: newSentecesTranslate,
          };
        }

        return sentence;
      }),
    }),
  },
});

export const {
  reset, addSentenсe, delSentenсe, changeSentenсe, loadTopic, updateTopic,
} = topicSlice.actions;

export default topicSlice.reducer;
