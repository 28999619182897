import React, { useEffect, useState } from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { FolderOpen } from '@mui/icons-material';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Col, Row } from 'antd';
import { ButtonEditTopic } from '../component/ButtonEditTopic';
import { LinkRuleType, TopicType } from '../Type';
import { loadTopic } from '../features/topic/topicSlice';
import { TopicWords } from '../component/TopicWords';
import { selectSentences } from '../app/store';
import { ViewerCardDef } from '../component/ViewerCardDef';
import { ViewerStepper } from '../component/ViewerStepper';

export const TopicViewer: React.FC<any> = () => {
  const { topicId } = useParams();
  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);

  const [linkRules, setLinkRules] = useState<LinkRuleType[]>([]);
  const topic = useSelector(selectSentences);

  useEffect(() => {
    (async () => {
      if (topicId !== topic.topic_id) {
        const topicData = (await axios.get<TopicType>(`${process.env.REACT_APP_URL_API}/data/topics/${topicId}`)).data;
        dispatch(loadTopic(topicData));
      }
    })();
  }, [topicId]);

  console.log(linkRules);

  return (
  <>
    <Box className='header_topic' sx={{
      display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '40px',
    }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FolderOpen style={{ fontSize: '20px', color: '#0475ab', marginRight: '10px' }} />
          <p style={ { fontSize: 'large' }}>TopicViewer</p>
        </div>
        <div>
          <ButtonEditTopic />
        </div>
    </Box>
    <Box className='buttons_level' sx={{
      marginBottom: '40px',
    }}>
      <ViewerStepper current={current} setCurrent={setCurrent} />
    </Box>
    <Box>
      <Row>
        <Col span={14}>
          <Typography variant="h6" component="h6">TOPIC</Typography>
          <Box sx={{
            padding: '20px',
            backgroundColor: '#eceff1',
            boxShadow: '0 13px 70px -55px #141645',
            height: 500,
            fontSize: '2em',
          }}>
            <TopicWords sentenceIndex={current} setLinkRules={setLinkRules}/>
          </Box>
        </Col>
        <Col span={10} style={{
          paddingLeft: '40px',
        }}>
          <Typography variant="h6" component="h6">DEFINITIONS</Typography>
          <Box sx={{
            padding: '20px',
            backgroundColor: '#e0f7fa',
            boxShadow: '0 13px 70px -55px #141645',
            height: 500,
            overflowY: 'scroll',
            boxSizing: 'border-box',
          }}>
            {
              linkRules.map((linkRule, index) => <ViewerCardDef linkRule={linkRule} key={index}/>)
            }
          </Box>
        </Col>
      </Row>
    </Box>
  </>
  );
};
