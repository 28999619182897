import React, {
  ChangeEvent, useCallback, useEffect, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { SentenceTranslateType } from '../Type';
import { ButtonEditRulesSentenсe } from './ButtonEditRulesSentenсe';
import { changeSentenсe, updateTopic } from '../features/topic/topicSlice';
import { ButtonConfirmEditSentenсe } from './ButtonConfirmEditSentenсe';
import { ButtonClearRulesSentenсe } from './ButtonClearRulesSentenсe';

type SentencesTranslateProps = {
  sentence: SentenceTranslateType
};

export const SentencesTranslate: React.FC<SentencesTranslateProps> = (props) => {
  const dispatch = useDispatch();

  const [sentenceTranslate, setSentenceTranslate] = useState<SentenceTranslateType>(props.sentence);

  if (props.sentence.isEditRules && sentenceTranslate.text !== props.sentence.text) {
    setSentenceTranslate(props.sentence);
  }

  useEffect(() => {
    dispatch(updateTopic(sentenceTranslate));
  }, [sentenceTranslate]);

  const changeTextInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const newSentence = {
      ...sentenceTranslate,
      text: event.target.value,
      isEditRules: true,
    };

    dispatch(changeSentenсe(newSentence));
    setSentenceTranslate(newSentence);
  }, [sentenceTranslate]);

  return (
    <>
      {
          !!sentenceTranslate
          && <div style={{ display: 'flex' }}>
          <TextField label="Sentence"
                     variant="outlined"
                     value={!!sentenceTranslate && sentenceTranslate.text}
                     size="small"
                     onChange={changeTextInput}
                     fullWidth
                     margin="dense"
                     disabled={!props.sentence.isEditRules} />
            <ButtonConfirmEditSentenсe sentence={sentenceTranslate} setSentence={setSentenceTranslate}/>
            <ButtonEditRulesSentenсe sentence={sentenceTranslate} setSentence={setSentenceTranslate}/>
            <ButtonClearRulesSentenсe sentence={sentenceTranslate} setSentence={setSentenceTranslate}/>
          </div>
      }
    </>
  );
};
