import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { FolderOpen } from '@mui/icons-material';
import { TopicType } from '../Type';
import { ButtonAddTopic } from '../component/ButtonAddTopic';
import { loadAllTopics } from '../features/allTopics/allTopicsSlice';
import { selectTopics } from '../app/store';
import { TopicCard } from '../component/TopicCard';

export const TopicPage: React.FC = () => {
  const topics = useSelector(selectTopics);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const topicData = (await axios.get<TopicType[]>(`${process.env.REACT_APP_URL_API}/data/topics`)).data;
      dispatch(loadAllTopics(topicData));
    })();
  }, []);

  return (
      <div>
          <div className='topic_list'>
              <div style={{
                display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 40,
              }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <FolderOpen style={{ fontSize: '20px', color: '#0475ab', marginRight: '10px' }} />
                    <p style={ { fontSize: 'large' }}>Topics</p>
                  </div>
                  <div>
                      <ButtonAddTopic />
                  </div>
              </div>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              topics.map((topic, key) => (
                  <div key={key} style={{ paddingLeft: '24px', paddingBottom: '24px' }}>
                     <TopicCard topic={topic} />
                  </div>
              ))
            }
          </div>
          </div>
      </div>
  );
};
