import React, {
  Dispatch, SetStateAction,
  useCallback,
} from 'react';
import {
  IconButton,
} from '@mui/material';
import { DeleteSweepOutlined } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { SentenceTranslateType } from '../Type';
import { selectSentences } from '../app/store';

type ButtonClearRulesSentenceProps = {
  sentence: SentenceTranslateType,
  setSentence: Dispatch<SetStateAction<SentenceTranslateType>>,
};

export const ButtonClearRulesSentenсe: React.FC<ButtonClearRulesSentenceProps> = (props) => {
  const topic = useSelector(selectSentences);

  const clickClear = useCallback(() => {
    const actualSentence = topic.topic_data
      .filter((sentence) => sentence.id === props.sentence.parentId)[0];

    const preIndex = actualSentence.sentencesTranslate.map((sentenceTranslate, index) => {
      if (sentenceTranslate.id === props.sentence.id && index > 0) {
        return --index;
      }
      return -1;
    })
      .filter((index) => index >= 0)[0];

    console.log(preIndex);
    const newSentenceTranslate = {
      ...props.sentence,
      isEditRules: true,
      linkRules: [],
      words: [],
      text: actualSentence.sentencesTranslate[preIndex].text,
    };

    console.log('updateTopic', newSentenceTranslate);
    props.setSentence(newSentenceTranslate);
  }, [topic]);

  return (
    <>
        <IconButton disabled={!!props.sentence.isEditRules} color="error" aria-label="clear" onClick={clickClear}>
            <DeleteSweepOutlined/>
        </IconButton>
    </>
  );
};
