import React, {
  ChangeEvent, useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@mui/material';
import { ButtonEditSentenсe } from './ButtonEditSentenсe';
import { SentenceType } from '../Type';
import { changeSentenсe } from '../features/topic/topicSlice';
import { selectSentences } from '../app/store';
import { ButtonDelSentenсe } from './ButtonDelSentenсe';

type SentencesProps = {
  sentence: SentenceType,
  inFocus: boolean,
};

export const Sentences: React.FC<SentencesProps> = (props) => {
  const dispatch = useDispatch();
  const [sent, setSent] = useState(props.sentence.sentencesTranslate[0].text);
  const topic = useSelector(selectSentences);

  const changeTextInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSent(event.target.value);
    dispatch(changeSentenсe({
      ...props.sentence.sentencesTranslate[0],
      text: event.target.value,
    }));
  }, [sent]);

  const oldSentence = topic.topic_data.filter((sentence) => sentence.id === props.sentence.id)[0];

  return (
    <div className='sentence'>
      <TextField hiddenLabel
                 placeholder="Text Sentence"
                 variant="outlined"
                 value={oldSentence.sentencesTranslate[0].text}
                 size="small"
                 onChange={changeTextInput}
                 fullWidth
                 margin="normal"
                 autoFocus={props.inFocus}/>
      <ButtonEditSentenсe sentence={oldSentence}/>
      <ButtonDelSentenсe sentence={oldSentence} />
    </div>
  );
};
