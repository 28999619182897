import React, {
  useCallback, useState,
} from 'react';
import {
  Box, DialogTitle, Divider, IconButton, Modal, Typography,
} from '@mui/material';
import { Close, TranslateOutlined } from '@mui/icons-material';
import { SentenceType } from '../Type';
import { SentencesTranslate } from './SentenсesTranslate';
import { styleTopicModal } from '../style/styled';

type ButtonEditSentenceProps = {
  sentence: SentenceType,
};

export const ButtonEditSentenсe: React.FC<ButtonEditSentenceProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const { sentence } = props;
  const clickShow = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCancel = async () => {
    setIsModalVisible(false);
  };

  return (
    <>
        <IconButton color="primary" aria-label="" onClick={clickShow}>
            <TranslateOutlined color={'primary'}/>
        </IconButton>
      <Modal open={isModalVisible}
             onClose={handleCancel}>
        <Box sx={styleTopicModal} >
            <DialogTitle>
                <Box display="flex" alignItems="center" fontSize={16}>
                    <Box flexGrow={1}>Edit Sentence</Box>
                    <Box>
                        <IconButton onClick={handleCancel}>
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
            <Divider />
            <Box marginTop={'20px'}>
                <div>
                  {
                    sentence.sentencesTranslate.map((sentTrans, index) => {
                      if (!index) {
                        return (
                          <Box textAlign={'center'} marginBottom={'10px'} key={index}>
                              <Typography component='h6'>
                                  Main Sentence
                              </Typography>
                              <Typography component='h2' fontSize={'20px'} fontWeight={'bold'}>
                                  {sentTrans.text}
                              </Typography>
                          </Box>
                        );
                      }
                      return <Box key={index} visibility={(!sentence.sentencesTranslate[0].words.length && index > 1) ? 'hidden' : 'visible'}>
                            <SentencesTranslate sentence={sentTrans} />
                        </Box>;
                    })
                  }
                </div>
            </Box>
        </Box>
      </Modal>
    </>
  );
};
