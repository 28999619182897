import React, {
  Ref,
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useParams } from 'react-router';
import {
  Alert,
  AlertTitle,
  Autocomplete, Box,
  Button,
  Divider, Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  FolderOpen,
  ModeEdit, Save,
} from '@mui/icons-material';

import { Col, Row } from 'antd';
import { Sentences } from '../component/Sentenсes';
import { selectSentences, selectTopics } from '../app/store';
import { addSentenсe, loadTopic } from '../features/topic/topicSlice';
import { SentenceType, TopicType } from '../Type';
import { addTopic, loadAllTopics } from '../features/allTopics/allTopicsSlice';
import { ButtonViewTopic } from '../component/ButtonViewTopic';

const TIMEOUT = 60 * 1000; // 60 sec

export const TopicEditor: React.FC = () => {
  const { topicId } = useParams();
  const topic = useSelector(selectSentences) as TopicType;
  const allTopic = useSelector(selectTopics);
  const dispatch = useDispatch();
  const [flagSave, setFlagSave] = useState(false);
  const [flagSaveTopic, setFlagSaveTopic] = useState(false);
  const refCaption = useRef<HTMLInputElement>();
  const refGroup = useRef<HTMLInputElement>();
  const refTags = useRef<HTMLInputElement>();
  const refTheme = useRef<HTMLInputElement>();
  const [tags, setTags] = useState<string[]>([]);
  const [allTags, setAllTags] = useState<string[]>([]);

  useEffect(() => {
    if (flagSave) {
      setTimeout(() => {
        setFlagSave(false);
      }, 1000);
    }
  }, [flagSave]);

  const saveTopic = useCallback(async () => {
    const resultSaveDB = (await axios.post<any>(`${process.env.REACT_APP_URL_API}/data/save`, topic));

    if (resultSaveDB.status === 201) {
      setFlagSave(true);
      if (!allTopic.filter((top) => top.topic_id === topic.topic_id).length) {
        dispatch(addTopic(topic));
      } else {
        const newAllTopics = allTopic.map((oneTopic) => (oneTopic.topic_id === topic.topic_id ? topic : oneTopic));
        dispatch(loadAllTopics(newAllTopics));
      }
    }
    return resultSaveDB;
  }, [topic]);

  useEffect(() => {
    if (flagSaveTopic && topic.name) {
      saveTopic();
    }
    setTimeout(() => {
      setFlagSaveTopic(false);
    }, TIMEOUT);
  }, [flagSaveTopic]);

  useEffect(() => {
    setFlagSaveTopic(true);
  }, [topic]);

  useEffect(() => {
    (async () => {
      let topicData = topic;

      if (topicId && !topic.name) {
        topicData = (await axios.get<TopicType>(`${process.env.REACT_APP_URL_API}/data/topics/${topicId}`)).data;
        dispatch(loadTopic(topicData));
      }

      const topicTags = (await axios.get<string[]>(`${process.env.REACT_APP_URL_API}/data/tags`)).data;
      setAllTags(topicTags);
      if (topicData.tagsArr) {
        setTags(topicData.tagsArr);
      }
    })();
  }, []);

  const addSent = useCallback(() => {
    dispatch(addSentenсe({
      text: '',
      words: [],
      linkRules: [],
    }));
  }, []);

  const changeTextInput = useCallback(() => {
    dispatch(loadTopic({
      ...topic,
      theme: refTheme.current?.value!,
      topic_group: refGroup.current?.value!,
      name: refCaption.current?.value!,
    }));
  }, [topic]);

  const changeAutocomplete = useCallback((event:any, newValue:string[]) => {
    setTags(newValue);
    dispatch(loadTopic({
      ...topic,
      tagsArr: newValue,
    }));
  }, [topic]);

  return (
  <>
     <Snackbar open={flagSave} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            Topic saved!
          </Alert>
     </Snackbar>
     <Box className='header_topic' sx={{
       display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '40px',
     }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FolderOpen style={{ fontSize: '20px', color: '#0475ab', marginRight: '10px' }} />
          <p style={ { fontSize: 'large' }}>TopicEditor</p>
        </div>
        <div>
          <ButtonViewTopic />
        </div>
    </Box>
     <Box>
      <Row gutter={16}>
        <Col className="gutter-row" span={10}>
          <TextField label="Post Title"
                     variant="outlined"
                     value={topic.name}
                     inputRef={refCaption as Ref<HTMLInputElement>}
                     size="medium"
                     onChange={changeTextInput}
                     fullWidth
                     margin="normal" />
          <Autocomplete
              ref={refTags}
              multiple
              freeSolo
              value={tags}
              onChange={changeAutocomplete}
              options={allTags}
              renderInput={(params) => <TextField {...params} variant="outlined" margin="normal" label="Tags"/>} />
          <TextField label="Group"
                     variant="outlined"
                     value={topic.topic_group}
                     inputRef={refGroup}
                     size="medium"
                     onChange={changeTextInput}
                     fullWidth
                     margin="normal"/>
        </Col>
        <Col className="gutter-row" span={14}>
          <Box>
            <Typography component="h3" display={'flex'}>Cover</Typography>
            <Box sx={{
              backgroundColor: '#F4F6F8',
              height: '100%',
            }}>
              Cover
            </Box>
          </Box>

        </Col>
      </Row>

    </Box>
    <Divider style={{ margin: '10px 5px' }}/>
    <div className='data_topic'>
      <Typography component="h2" fontWeight={'bold'} fontSize={'18px'}>
        Sentences
      </Typography>
      {
          topic.topic_data.map((sentence:SentenceType, index:number) => {
            let inFocus = false;
            if (index === topic.topic_data.length - 1) {
              inFocus = true;
            }
            return <Sentences key={index} sentence={sentence} inFocus={inFocus} />;
          })
      }
    </div>
    <div className='btn_action'>
      <Stack direction="row" spacing={2}>
        <Button variant="outlined" startIcon={<ModeEdit />} onClick={addSent}>
          Add
        </Button>
        <Button variant="contained" startIcon={<Save />} onClick={saveTopic}>
          Save
        </Button>
      </Stack>
    </div>
  </>
  );
};
