import React, { useCallback, useState } from 'react';
import {
  Avatar, Box, Card, CardActions, CardContent, CardHeader, CardMedia, IconButton, Menu, MenuItem,
} from '@mui/material';
import { Favorite, MoreVert, Share } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { TopicType } from '../Type';
import { delTopic } from '../features/allTopics/allTopicsSlice';

type TopicCardProps = {
  topic: TopicType,
};

export const TopicCard: React.FC<TopicCardProps> = (props) => {
  const { topic } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const clickMore = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('clickMore');
    setAnchorEl(event.currentTarget);
  }, []);

  const clickDel = useCallback(async (e: any) => {
    const result = (await axios.post(`${process.env.REACT_APP_URL_API}/data/delete/${topic.topic_id}`)).data;
    console.log(topic, e.target);
    if (result.affected && topic.topic_id) {
      dispatch(delTopic(topic.topic_id));
    }
    setAnchorEl(null);
  }, [topic]);

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
          avatar={
            <Avatar sx={{ bgcolor: '#F8AAF5' }} aria-label="topic">
              R
            </Avatar>
          }
          action={
          <Box>
              <IconButton aria-label="settings" onClick={clickMore}>
                  <MoreVert/>
              </IconButton>
              <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
              >
                  <MenuItem><Link to={`/topic/${topic.topic_id}`} style={{ color: 'rgb(0, 0, 0, 0.87)' }}>Go topic</Link></MenuItem>
                  <MenuItem onClick={clickDel}>Delete</MenuItem>
              </Menu>
          </Box>

          }

          title={<Link to={`/topic/${topic.topic_id}`} style={{ fontWeight: 'bold' }}>{topic.name}</Link>}
          subheader="November 22, 2022"
      />
      <CardMedia
          component="img"
          height="194"
          // image="http://weatherapp.ditime.com.ua/img/v748-toon-111.jpg"
          /* eslint-disable-next-line global-require */
          // image={require('../image/card_back.jpg')}
          alt=""
          style={{
            background: 'linear-gradient(90deg, rgba(0,21,41,1) 0%, rgba(167,200,240,1) 100%)',
          }}
      />
      <CardContent>
        {topic.theme}
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <Favorite/>
        </IconButton>
        <IconButton aria-label="share">
          <Share/>
        </IconButton>
      </CardActions>
    </Card>
  );
};
