import React, {

} from 'react';
import { Button } from '@mui/material';
import { Settings } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectSentences } from '../app/store';

type ButtonViewTopicProps = {
};

export const ButtonViewTopic: React.FC<ButtonViewTopicProps> = () => {
  const topic = useSelector(selectSentences);

  return (
    <>
        <Link to={`/topic/${topic.topic_id}`} style={{ fontWeight: 'bold' }}>
         <Button variant="contained" startIcon={<Settings />}>View Topic</Button>
        </Link>
    </>
  );
};
