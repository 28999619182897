import React, {
  Dispatch, SetStateAction,
  useCallback, useState,
} from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton,
} from '@mui/material';
import { Spellcheck, TipsAndUpdatesOutlined } from '@mui/icons-material';
import { SentenceTranslateType } from '../Type';

type ButtonConfirmEditSentenсeProps = {
  sentence: SentenceTranslateType,
  setSentence: Dispatch<SetStateAction<SentenceTranslateType>>,
};

export const ButtonConfirmEditSentenсe: React.FC<ButtonConfirmEditSentenсeProps> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const clickShow = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCancel = async () => {
    setIsModalVisible(false);
  };

  const confirmEdit = () => {
    setIsModalVisible(false);

    const newSentenceTranslate: SentenceTranslateType = {
      ...props.sentence,
      isEditRules: false,
    };

    console.log('updateTopic');
    props.setSentence(newSentenceTranslate);
  };

  return (
    <>
        <IconButton disabled={!props.sentence.isEditRules} color="primary" aria-label="confirm edit" onClick={clickShow}>
            <Spellcheck/>
        </IconButton>
        <Dialog
            open={isModalVisible}
            onClose={handleCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" sx={{
              padding: '20px',
              alignItems: 'center',
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {<TipsAndUpdatesOutlined color={'error'} sx={{ fontSize: '36px', marginRight: '10px' }} /> }
                    {'Подтвердите действие!'}
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" textAlign={'justify'}>
                   После подтверждения редактирования предложение будет заблокировано. Вы уверены?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Disagree</Button>
                <Button onClick={confirmEdit} autoFocus> Agree </Button>
            </DialogActions>
        </Dialog>
    </>
  );
};
