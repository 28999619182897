// eslint-disable-next-line import/no-unresolved
import { TopicPage } from '../page/TopicPage';
// eslint-disable-next-line import/no-unresolved
import { TopicEditor } from '../page/TopicEditor';
// eslint-disable-next-line import/no-unresolved
import { TopicViewer } from '../page/TopicViewer';

export const routers = [
  {
    path: '/topics', elem: <TopicPage />, key: 'topics', caseSensitive: false,
  },
  {
    path: '/topic/edit/:topicId', elem: <TopicEditor />, key: 'topic', caseSensitive: false,
  },
  {
    path: '/topic/:topicId', elem: <TopicViewer />, key: 'topicView', caseSensitive: false,
  },
];

//
// <Route path={'/topics'}
//        key={'topics'}
//        caseSensitive={false}
//        element={<TopicPage />}
// />
//
// <Route path={'/topic/edit/:topicId'}
//        key={'topic'}
//        caseSensitive={false}
//        element={<TopicEditor />}
// />
// <Route path={'/topic/:topicId'}
//        key={'topicView'}
//        caseSensitive={false}
//        element={<TopicViewer />}
// />
