import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { TopicType } from '../../Type';

const initialState: TopicType[] = [];

export const allTopicsSlice = createSlice({
  name: 'allTopics',
  initialState,
  reducers: {
    addTopic: (state: TopicType[], action: PayloadAction<TopicType>) => [
      ...state,
      action.payload,
    ],
    delTopic: (state: TopicType[], action: PayloadAction<string>) => state.filter((topic) => topic.topic_id !== action.payload),
    loadAllTopics: (state: TopicType[], action:PayloadAction<TopicType[]>) => action.payload,
  },
});

export const {
  addTopic, delTopic, loadAllTopics,
} = allTopicsSlice.actions;

export default allTopicsSlice.reducer;
