import React, {
} from 'react';
import { IconButton } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { deleteRules } from '../features/sentence/sentenceTranslateSlice';

type ButtonDeleteRulesProps = {
  linkRuleId: string,
};

export const ButtonDeleteRules: React.FC<ButtonDeleteRulesProps> = (props) => {
  const dispatch = useDispatch();

  const clickDel = () => {
    console.log('click del rules');
    dispatch(deleteRules(props.linkRuleId));
  };

  return (
    <>
        <IconButton size={'small'} color="primary" aria-label="" onClick={clickDel}>
            <DeleteForever color={'error'}/>
        </IconButton>
    </>
  );
};
