import { ConstRules } from '../Type';

export class HelperData {
  static readonly rules = [
    {
      id: 1,
      text: 'to BE forms',
    },
    {
      id: 2,
      text: 'Infinitive',
    },
    {
      id: 3,
      text: 'gerund',
    },
    {
      id: 4,
      text: 'modals',
    },
    {
      id: 5,
      text: 'question words',
    },
    {
      id: 6,
      text: 'definite article',
    },
    {
      id: 7,
      text: 'indefinite article',
    },
    {
      id: 8,
      text: 'subject pronoun',
    },
    {
      id: 9,
      text: 'object pronoun',
    },
    {
      id: 10,
      text: 'possessive adjective',
    },
    {
      id: 11,
      text: 'possessive pronoun',
    },
    {
      id: 12,
      text: 'Present Simple',
    },
    {
      id: 13,
      text: 'Present Continuous',
    },
    {
      id: 14,
      text: 'Past Simple',
    },
    {
      id: 15,
      text: 'Past Simple',
    },
    {
      id: 16,
      text: 'Past Continuous',
    },
    {
      id: 17,
      text: 'Future Simple',
    },
    {
      id: 18,
      text: 'Future Continuous',
    },
    {
      id: 19,
      text: 'Present Perfect',
    },
    {
      id: 20,
      text: 'Past Perfect',
    },
    {
      id: 21,
      text: 'Future Perfect',
    },
    {
      id: 22,
      text: 'NOUN',
    },
    {
      id: 23,
      text: 'PRONOUN',
    },
    {
      id: 24,
      text: 'VERB',
    },
  ];

  static readonly rulesNew: ConstRules[] = [
    {
      key: '100',
      tab: 'Noun',
      children: [
        {
          id: '1',
          text: 'countable',
        },
        {
          id: '2',
          text: 'uncountable',
        },
        {
          id: '3',
          text: 'plural',
        },
      ],
    },
    {
      key: '200',
      tab: 'Pronoun',
      children: [
        {
          id: '4',
          text: 'subject',
        },
        {
          id: '5',
          text: 'object',
        },
        {
          id: '6',
          text: 'possessive',
        },
        {
          id: '7',
          text: 'possessive adjective',
        }],
    },
    {
      key: '300',
      tab: 'Verb',
      children: [
        {
          id: '8',
          text: 'gerund',
        },
        {
          id: '9',
          text: 'infinitive',
        },
        {
          id: '20',
          text: 'phrasal verbs',
        },
        {
          id: '21',
          text: 'Present Simple',
        },
        {
          id: '22',
          text: 'Present Continuous',
        },
        {
          id: '23',
          text: 'Past Simple',
        },
        {
          id: '24',
          text: 'Past Simple',
        },
        {
          id: '25',
          text: 'Past Continuous',
        },
        {
          id: '26',
          text: 'Future Simple',
        },
        {
          id: '27',
          text: 'Future Continuous',
        },
        {
          id: '28',
          text: 'Present Perfect',
        },
        {
          id: '29',
          text: 'Past Perfect',
        },
        {
          id: '30',
          text: 'Future Perfect',
        },
      ],
    },
    {
      key: '400',
      tab: 'Adjective',
      children: [
        {
          id: '31',
          text: 'comparative form',
        },
        {
          id: '32',
          text: 'superlative form',
        },
      ],
    },
    {
      key: '500',
      tab: 'Other',
      children: [
        {
          id: '11',
          text: 'adverb',
        },
        {
          id: '12',
          text: 'preposition',
        },
        {
          id: '13',
          text: 'conjunction',
        },
        {
          id: '14',
          text: 'interjection',
        },
        {
          id: '15',
          text: 'numerals',
        },
        {
          id: '40',
          text: 'proper noun',
        },
        {
          id: '16',
          text: 'be going to',
        },
        {
          id: '17',
          text: 'would like (to)',
        },
        {
          id: '18',
          text: 'fixed phrases and collocations',
        },
      ],
    },
  ]
}
