import React, {
  Dispatch, SetStateAction,

} from 'react';
import { Steps } from 'antd';
import { useSelector } from 'react-redux';
import { selectSentences } from '../app/store';

type ViewerStepperProps = {
  current: number,
  setCurrent: Dispatch<SetStateAction<number>>
};

export const ViewerStepper: React.FC<ViewerStepperProps> = ({ current, setCurrent }) => {
  const { Step } = Steps;
  const topic = useSelector(selectSentences);

  const onChange = (value: number) => {
    setCurrent(value);
  };

  return (
      <Steps current={current} onChange={onChange}>
          {
              topic.topic_data.length
                ? topic.topic_data[0].sentencesTranslate.map((_, index) => <Step key={index} />)
                : null
          }
      </Steps>
  );
};
