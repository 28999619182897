import React, {
  useCallback,
} from 'react';
import {
  IconButton,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { SentenceType } from '../Type';
import { delSentenсe } from '../features/topic/topicSlice';

type ButtonDelSentenceProps = {
  sentence: SentenceType,
};

export const ButtonDelSentenсe: React.FC<ButtonDelSentenceProps> = (props) => {
  const { sentence } = props;
  const dispatch = useDispatch();

  const clickDelete = useCallback(() => {
    dispatch(delSentenсe(sentence.id));
  }, []);

  return (
    <>
        <IconButton color="error" aria-label="" onClick={clickDelete}>
            <DeleteOutline />
        </IconButton>
    </>
  );
};
