import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  LinkRuleType,
} from '../../Type';

const initialState: LinkRuleType = {
  startWords: [],
  words: [],
  rules: [],
  description: '',
  id: '',
};

export const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
    addRules: (state: LinkRuleType, action:PayloadAction<LinkRuleType>) => {
      console.log(state, action.payload);
    },
  },
});

export const {
  reset, addRules,
} = rulesSlice.actions;

export default rulesSlice.reducer;
