export const styleTopicModal = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '10px 30px 30px',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
};

export const styleTopicRules = {
  fontSize: '20px',
  color: '#424242',
};

export const styleTopicRulesBold = {
  fontSize: '20px',
  fontWeight: 'bold',
  color: '#424242',
};
