import React, { useEffect, useState } from 'react';
import './style/App.css';
import './style/card.css';
import {
  ProjectOutlined,
  UserOutlined,
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import {
  Layout, Menu,
} from 'antd';
import {
  Routes,
  useNavigate, Route,
} from 'react-router-dom';
import { Provider } from 'react-redux';

import { store } from './app/store';
import { HeaderContent } from './component/HeaderContent';
import { routers } from './router';

type MenuItem = Required<MenuProps>['items'][number];

const {
  Header, Content, Footer, Sider,
} = Layout;

function App() {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const navigate = useNavigate();
  const structureIdFromPath = window.location.pathname.split('/');
  const [currentStructureId, setCurrentStructureId] = useState<string>(structureIdFromPath[1]);

  if (currentStructureId !== structureIdFromPath[1]) {
    setCurrentStructureId(structureIdFromPath[1]);
  }

  useEffect(() => {
    const getItem = (
      label: React.ReactNode,
      key: React.Key,
      icon?: React.ReactNode,
      children?: MenuItem[],
    ): MenuItem => ({
      key,
      icon,
      children,
      label,
    } as MenuItem);

    const newMenuItems = [
      getItem('Users', 'users', <UserOutlined/>),
      getItem('Topics', './topics', <ProjectOutlined/>),
    ];

    setMenuItems(newMenuItems);
  }, []);

  return (
        <div className="App">
            <div>
                <Layout style={{ minHeight: '100vh' }}>
                    <Layout>
                        <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)} theme={'dark'}>
                            <div className="logo">

                            </div>
                            <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline" items={menuItems} style={{ height: '100%' }}
                                  onClick={(e: any) => {
                                    navigate(`/${e.key}`);
                                  }}/>
                        </Sider>
                        <Layout>
                            <Header className="" style={{ padding: '0 16px' }} >
                                <HeaderContent />
                            </Header>
                            <Content style={{ padding: '0 16px' }}>
                                {
                                      <div className="site-layout-background"
                                             style={{ padding: 24, minHeight: 360, position: 'relative' }}>
                                          <Routes>
                                              {
                                                  routers.map((route) => <Route
                                                          key={route.key}
                                                          path={route.path}
                                                          element={route.elem}
                                                          caseSensitive={route.caseSensitive}
                                                  />)
                                              }
                                          </Routes>
                                        </div>
                                }
                            </Content>
                        </Layout>
                    </Layout>
                    <Footer style={{ textAlign: 'center' }}>Lingvika ©2023 Created by DiTime Team</Footer>
                </Layout>
            </div>
        </div>
  );
}

const AppWrapper = () => (
    <Provider store={store}>
        <App />
    </Provider>
);

export default AppWrapper;
