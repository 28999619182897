import React, {
  useState,
} from 'react';
import {
  Box, Collapse, ListItemButton, Paper, Button,
} from '@mui/material';
import { ExpandLess, ExpandMore, RecordVoiceOver } from '@mui/icons-material';
import { Col, Row } from 'antd';
import { styleTopicRules, styleTopicRulesBold } from '../style/styled';
import { LinkRuleType } from '../Type';

type CardDefProps = {
  linkRule: LinkRuleType
};

export const ViewerCardDef: React.FC<CardDefProps> = ({ linkRule }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
        <Paper sx={{
          padding: '10px',
          marginBottom: '30px',
        }}>
            <Row gutter={5}>
                <Col className="gutter-row" span={24}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <p style={styleTopicRulesBold}>{linkRule.words.map((word) => word.text).join(' ')}</p> -
                        <p style={styleTopicRulesBold}>{(!!linkRule.startWords && linkRule.startWords.map((word) => word.text).join(' ')) || '[.....]'}</p>

                    </Box>
                </Col>
            </Row>
            <Row gutter={5}>
                <Col className="gutter-row" span={24}>
                    <Box>
                        {linkRule.rules.map((rule, subIndex) => <p style={styleTopicRules} key={subIndex}>{rule.rule}</p>)}
                    </Box>
                </Col>
            </Row>
            <Row gutter={5}>
                <Col className="gutter-row" span={24}>
                    <Box>
                        {<p>{linkRule.description}</p>}
                    </Box>
                </Col>
            </Row>
            {/* <Divider orientation={'horizontal'} flexItem> */}
            {/*  <p style={{ fontWeight: 700, fontSize: '20px', color: '#424242' }}>Definition: </p> */}
            {/* </Divider> */}
            <Row>
                <Col span={24}>
                    <Box sx={{ marginTop: '20px' }}>
                        <Row gutter={5}>
                            <Col className="gutter-row" span={24}>
                        {
                            linkRule.words.map((word) => {
                              if (!word.translate) {
                                return null;
                              }

                              return word.translate.map((wordTransl, key) => {
                                // c API получаем несколько переводов, отображаем только первый (key = 0)
                                if (key) {
                                  return null;
                                }
                                // если в переводе более 1 слова, карточки не выводим, ТОЛЬКО на одиночне слова
                                if (linkRule.words.length > 1) {
                                  return null;
                                }

                                return (
                                <Box key={key}>
                                    <Row gutter={5}>
                                        <Col className="gutter-row" span={24}>
                                            <ListItemButton onClick={handleClick} sx={{
                                              display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'space-between', padding: '8px',
                                            }}>
                                                <Box sx={{
                                                  display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'space-between',
                                                }}>
                                                            <p style={styleTopicRulesBold}>{wordTransl.word}</p>
                                                            {
                                                                wordTransl.phonetics[0] && wordTransl.phonetics[0].audio
                                                                  ? <Button size="small" variant="outlined">
                                                                        <RecordVoiceOver onClick={async () => {
                                                                          const audio = new Audio(wordTransl.phonetics[0].audio);
                                                                          await audio.play();
                                                                        }} />
                                                                    </Button>
                                                                  : null
                                                            }
                                                            <p>{word.translate?.[0].phonetic}</p>

                                                </Box>
                                                {open ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                            <Collapse in={open} timeout="auto" unmountOnExit>
                                                <Box sx={{ marginBottom: '20px' }}>
                                                    {word.translate?.[0].meanings.map((meen, subkey) => (
                                                        <Box key={subkey}>
                                                            <p style={{ color: '#999aa9', fontSize: '26px' }}>{meen.partOfSpeech}</p>
                                                            {
                                                                meen.definitions.map((def, ind) => {
                                                                  // console.log(def);
                                                                  if (ind > 1) {
                                                                    return null;
                                                                  }
                                                                  return <Box key={ind} sx={{
                                                                    display: 'flex', alignItems: 'flex-start', gap: 1, marginBottom: '5px',
                                                                  }}>
                                                                    <p style={{ color: '#1976d2' }}>{ind + 1}.</p>
                                                                    <p>{def.definition}</p>
                                                                </Box>;
                                                                })
                                                            }
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Collapse>
                                        </Col>
                                    </Row>
                                </Box>
                                );
                              });
                            })
                        }
                            </Col>
                        </Row>
                    </Box>
                </Col>
            </Row>
        </Paper>
  );
};
