import { configureStore } from '@reduxjs/toolkit';
import sentenceReduser from '../features/topic/topicSlice';
import sentenceTranslateReduser from '../features/sentence/sentenceTranslateSlice';
import topicsReduser from '../features/allTopics/allTopicsSlice';
import rulesReduser from '../features/rules/rulesSlice';

export const store = configureStore({
  reducer: {
    sentence: sentenceReduser,
    topics: topicsReduser,
    sentenceTranslate: sentenceTranslateReduser,
    rules: rulesReduser,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const selectSentences = (state:RootState) => state.sentence;
export const selectTopics = (state:RootState) => state.topics;
export const selectSentenceTranslate = (state:RootState) => state.sentenceTranslate;
export const selectRules = (state:RootState) => state.rules;
