import React, {
  useCallback, useState,
} from 'react';

import AddIcon from '@mui/icons-material/Add';
import {
  Box, Button, Modal,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { TopicEditor } from '../page/TopicEditor';
import { styleTopicModal } from '../style/styled';
import { reset } from '../features/topic/topicSlice';

type ButtonEditTopicProps = {
};

export const ButtonAddTopic: React.FC<ButtonEditTopicProps> = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleCancel = () => setOpen(false);
  const dispatch = useDispatch();

  const addTopic = useCallback((event: any) => {
    setOpen(true);
    dispatch(reset());
    console.log(event);
  }, []);

  return (
    <>
        <Button variant="contained" startIcon={<AddIcon />} onClick={addTopic}>Add Topic</Button>
        <Modal open={open}
                onClose={handleCancel}>
            <Box sx={styleTopicModal} >
                <div id={'json_test'} style={{ minHeight: 500 }} >
                    <TopicEditor />
                </div>
            </Box>
        </Modal>
    </>
  );
};
