import React, {
  Dispatch, SetStateAction, useCallback, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { LinkRuleType, SentenceType } from '../Type';
import { selectSentences } from '../app/store';

type TopicWordsProps = {
  sentenceIndex: number,
  setLinkRules: Dispatch<SetStateAction<LinkRuleType[]>>,
};

export const TopicWords: React.FC<TopicWordsProps> = (props) => {
  const topic = useSelector(selectSentences);
  const [currentIdLinkRules, setCurrentIdLinkRules] = useState<string[]>([]);
  const [saveIdLinkRules, setSaveIdLinkRules] = useState<string[]>([]);
  const [linkRules, setLinkRules] = useState<LinkRuleType[]>([]);

  const spanMouseOver = useCallback((event: any) => {
    setCurrentIdLinkRules(event.target.className.split(' '));
  }, []);

  const spanMouseLeave = useCallback(() => {
    setCurrentIdLinkRules([]);
  }, [linkRules]);

  const clickWords = useCallback((event: any) => {
    const linkRulesIds: string[] = event.target.className.split(' ');

    const linkRulesForSentence = topic.topic_data.map(
      (sentence) => sentence.sentencesTranslate[props.sentenceIndex].linkRules.filter(
        (linkRule) => linkRulesIds.includes(linkRule.id),
      ),
    );
    const sentenceLinkRules = linkRulesForSentence.reduce((cur, next) => cur.concat(next));

    const newWordsIds = sentenceLinkRules.map((rule) => rule.words.map((word) => word.id)).flat().filter((subword, index, pre) => pre.indexOf(subword) === index);

    const newLinkRules = topic.topic_data.map(
      (sentence) => sentence.sentencesTranslate[props.sentenceIndex].linkRules.filter(
        (linkRule) => linkRule.words.filter((word) => newWordsIds.includes(word.id)).length,
      ),
    ).flat().sort((a, b) => b.words.length - a.words.length);

    props.setLinkRules(newLinkRules);
    setLinkRules(newLinkRules);
    setSaveIdLinkRules(currentIdLinkRules);
  }, [props.sentenceIndex, linkRules, currentIdLinkRules]);

  return (
    <Box sx={{
      display: 'flex',
      flexWrap: 'wrap',
    }}>
        {
            props.sentenceIndex === 0
              ? topic.topic_data.map((sentence: SentenceType) => sentence.sentencesTranslate[props.sentenceIndex].text).join(' ')
              : topic.topic_data.map((sentence: SentenceType) => sentence.sentencesTranslate[props.sentenceIndex].words.map((word, index) => {
                const subLinkRules = sentence.sentencesTranslate[props.sentenceIndex].linkRules.filter((rule) => rule.words.filter((subWord) => subWord.id === word.id).length);
                const classSpan = subLinkRules.map((linkRule) => linkRule.id);
                return <span key={index}
                                 onMouseOver={spanMouseOver}
                                 onMouseLeave={spanMouseLeave}
                                 onClick={clickWords}
                                 id={`${sentence.id}_${sentence.sentencesTranslate[props.sentenceIndex].id}_${word.id}`}
                                 className={classSpan.join(' ')}
                                 style={{
                                   borderBottom: classSpan.length ? '2px dashed black' : '',
                                   cursor: classSpan.length ? 'pointer' : '',
                                   padding: '0 5px',
                                   lineHeight: 'auto !important',
                                   color: '#424242',
                                   backgroundColor: saveIdLinkRules.filter((x) => classSpan.includes(x)).length ? '#b3e5fc' : '',
                                   fontWeight: (currentIdLinkRules.filter((x) => classSpan.includes(x)).length
                                   || saveIdLinkRules.filter((x) => classSpan.includes(x)).length) ? 'bold' : '',
                                 }}>
                     {word.text}
                   </span>;
              }))
        }
    </Box>
  );
};
